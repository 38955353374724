import React from "react"

import DashboardLayout from "layout/DashboardLayout"
import PatientProfile from "../utils/PatientProfile"

const PatientProfilePage = ({ pageContext }) => {
  return (
    <DashboardLayout
      pageContext={pageContext}
      seoTitle={pageContext.module.title}
      title={pageContext.module.title}
      backPathUrl={pageContext.backPath}
    >
      <PatientProfile pageContext={pageContext} />
    </DashboardLayout>
  )
}

export default PatientProfilePage
